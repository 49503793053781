const Faq = () => {
   return (
      <>
         <div className="home_faq" id="faq">
            <div className="container">
               <div className="home_faq_max">
                  <div className="home_road_map_heading">
                     <h3 className="text-center gradient-title font-restaurant uppercase text-7xl">FREQUENTLY<br /> ASKED QUESTIONS</h3>
                  </div>
                  <div className="home_faq_flex">
                     <div className="home_faq_inner">
                        <div className="faq_1  ac-title" data-in="#tab1">
                           <h6 className="accordion">What are NFTs? What is Metamask? How do I buy an NFT?</h6>
                           <div className="panel accordian-para acc-show" id="tab1">
                              <p>In short, NFTs (Non-Fungible Tokens) are digital assets that you can buy, sell, or keep and hold. Their spike in popularity allows a modern approach to growing your online portfolio. Inside our discord, we have short and simple tutorials that explain what NFTs are and how to buy them with Metamask. Join the discord to learn more!</p>
                           </div>
                        </div>
                     </div>
                     <div className="home_faq_inner">
                        <div className="faq_1  ac-title" data-in="#tab2">
                           <h6 className="accordion">Is it safe to invest in NFTs?</h6>
                           <div className="panel accordian-para acc-show" id="tab2">
                              <p>Investing in NFTs in itself is safe, however, you need to choose wisely. Whether you go with us or someone else, you need an NFT that has the potential for exponential growth. We pride ourselves on loyalty and perseveres, ensuring the success of our community on a long-term basis.</p>
                           </div>
                        </div>
                     </div>
                     <div className="home_faq_inner">
                        <div className="faq_1  ac-title" data-in="#tab3">
                           <h6 className="accordion">What separates your project from others?</h6>
                           <div className="panel accordian-para acc-show" id="tab3">
                              <p>What separates our NFT project from others is the unique utilities that we provide. We provide you with members only access to our mobile app game where you can win rare NFTs to grow your NFT collection. We also own land in 3 different metaverses - Sandbox, Otherside (Bored Ape Yacht Club Land), and Cryptoverse and the best part is, we let our community decide what they want to do with the land. We are also doing crypto giveaways, as well as vacation giveaways to the beautiful Smoky Mountains, which by the way was ranked the top 10 vacations places to visit in the US by Forbes magazine.
                              </p></div>
                        </div>
                     </div>
                     <div className="home_faq_inner">
                        <div className="faq_1  ac-title" data-in="#tab4">
                           <h6 className="accordion">How do I purchase a Bear Camp NFT?</h6>
                           <div className="panel accordian-para acc-show" id="tab4">
                              <p>Starting on the Official Mint Date, you can purchase our NFT through our website. After that, all secondary purchases will be made through OpenSea.</p>
                           </div>
                        </div>
                     </div>
                     <div className="home_faq_inner">
                        <div className="faq_1  ac-title" data-in="#tab5">
                           <h6 className="accordion">What is the Bear Camp NFT Twitter, Instagram, and Discord?</h6>
                        </div>
                        <div className="panel accordian-para acc-show" id="tab5">
                           <p>Below you can find a list of verified links to our social media accounts. -Twitter: <a href="http://twitter.com/bearcampnfts" target="_blank">http://twitter.com/bearcampnfts</a><br />-Instagram: <a href="https://www.instagram.com/bearcampnfts/" target="_blank">https://www.instagram.com/bearcampnfts/</a><br />-Discord: <a href="https://discord.gg/wBmKB7J2td" target="_blank">https://discord.gg/wBmKB7J2td</a></p>
                        </div>
                     </div>
                  <div className="home_faq_inner">
                     <div className="faq_1  ac-title" data-in="#tab6">
                        <h6 className="accordion">How many Bear Camp NFTs are there?</h6>
                        <div className="panel accordian-para acc-show" id="tab6">
                           <p>There are 10,000 Bear Camp NFTs.</p>
                        </div>
                     </div>
                  </div>
                  <div className="home_faq_inner">
                     <div className="faq_1  ac-title" data-in="#tab7">
                        <h6 className="accordion">Where can I see my NFTs after the mint?</h6>
                        <div className="panel accordian-para acc-show" id="tab7">
                           <p>Where can I see my NFTs after the mint?</p>
                        </div>
                     </div>
                  </div>
                  <div className="home_faq_inner">
                     <div className="faq_1  ac-title" data-in="#tab8">
                        <h6 className="accordion">How do I Join the Whitelist?</h6>
                        <div className="panel accordian-para acc-show" id="tab8">
                           <p>Join our discord to find out more on how to get whitelisted!</p>
                        </div>
                     </div>
                  </div>
                  <div className="home_faq_inner">
                     <div className="faq_1  ac-title" data-in="#tab9">
                        <h6 className="accordion">What are the fees associated with selling my NFT?</h6>
                        <div className="panel accordian-para acc-show" id="tab9">
                           <p>There is a royalty of 8% when you sell your Bear Camp NFT on the Secondary Market. These funds are used to continue developing and progressing through our roadmap, as well as ensure the longevity of the project through marketing.</p>
                        </div>
                     </div>
                  </div>
                  <div className="home_faq_inner">
                     <div className="faq_1  ac-title" data-in="#tab10">
                        <h6 className="accordion">Do I own the Bear Camp NFT after minting it?</h6>
                        <div className="panel accordian-para acc-show" id="tab10">
                           <p>Yes. As a Non-fungible token, your wallet holds all the rights to the ownership of the image. You can do it as you wish. No one can claim they own it as everything is publicly available on the blockchain. If someone screenshots it, that would be the equivalent of someone taking a picture of the Mona Lisa, but all they go home with is a picture.</p>
                        </div>
                     </div>
                  </div>
                  <div className="home_faq_inner">
                     <div className="faq_1  ac-title" data-in="#tab11">
                        <h6 className="accordion">What is Mint Date, and what is Mint Price?</h6>
                        <div className="panel accordian-para acc-show" id="tab11">
                           <p>This will all be inside the discord channel.</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         </div>

            {/* <!-- Join discrod section --> */ }
   <div className="relative bg-black pt-12 join-discord">
      <div className=" discordbanner">
         <div className="h-full flex items-center">
            <div className="w-full sm:w-2/3 text-white text-center">
               <h1 className="text-6xl font-restaurant uppercase">Join us on discord</h1>
               <a href="https://discord.com/invite/wBmKB7J2td" className="px-16 py-3 border-2 font-bebas uppercase" target="_blank" rel="noreferrer">Join us now</a>
            </div>
         </div>
      </div>
   </div>
         </>
         );
}

export default Faq;