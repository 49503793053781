import './App.css';
import Navbar from './components/Navbar.js';
import Home from './components/Home.js';
import About from './components/About.js';
import Team from './components/Team.js';
import Roadmap from './components/Roadmap.js';
import Faq from './components/Faq.js';
import Footer from './components/Footer.js';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Home />
      <About />
      <Team />
      <Roadmap />
      <Faq />
      <Footer />
    </div>
  );
}

export default App;
