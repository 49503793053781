const Navbar = () => {
    return (
        <header className="header_main">
          <div className="container">
             <nav className="navbar navbar-expand-sm navbar-dark">
               {/* <!-- Brand --> */}
               <a className="navbar-brand logo" href="/">BEAR CAMP NFT</a>
               {/* <!-- Toggler/collapsibe Button --> */}
               <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                 <span className="navbar-toggler-icon"></span>
               </button>

               {/* <!-- Navbar links --> */}
               <div className="collapse navbar-collapse" id="collapsibleNavbar">
                 <ul className="navbar-nav">
                   <li className="nav-item active">
                     <a className="nav-link active" href="#home">Home</a>
                   </li>
                   <li className="nav-item">
                     <a className="nav-link" href="#about">About</a>
                   </li>
                   <li className="nav-item">
                     <a className="nav-link" href="#team">Team</a>
                   </li>
                   <li className="nav-item">
                     <a className="nav-link" href="#roadmap">Roadmap</a>
                   </li>
                   <li className="nav-item">
                     <a className="nav-link" href="#faq">FAQ</a>
                   </li>
                   <li class="nav-item" id="twitter_link"><a href="http://twitter.com/bearcampnfts" target="_blank"><i class="fa fa-twitter"></i></a></li>
                 </ul>
               </div>
             </nav>
          </div>
          <div className="h-0.5 w-full gradient-nav"></div>
    </header>
      );
}
 
export default Navbar;