import React, {useState, useEffect} from 'react';
import Web3 from 'web3';
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { CONTRACT_ADDRESS , CONTRACT_ABI } from '../config';



const Home = () => {
	return ( 
		<>
			<div id="home"><div class="relative"><div class="absolute top-10 sm:top-20 lg:top-32 w-full"><div class="flex justify-center sm:justify-end"><div class="sm:w-2/3 pl-3 pr-3 sm:pl-4 sm:pr-4"><div class="text-white text-center"><h1 class="font-restaurant uppercase text-6xl sm:text-7xl md:text-9xl lg:text-12xl">Get Exclusive Access to <br/><span class="gradient-title pr-3">our mobile app game</span></h1><p class="font-brandon  mx-auto text-sm lg:text-base"><p>Let us help you grow your NFT collection.</p></p>
			</div></div></div></div></div></div>
		</>
	 );
}
 
export default Home;