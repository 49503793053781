const Footer = () => {
    return ( 
        <>
            {/* <!-- Footer --> */}
            <footer className="bg-black font-brandon">
                <div className="px-4 overflow-hidden sm:px-6 lg:px-8">
                    <div className="pt-3 pb-3">
                        <div className="w-1/3 text-gray-700  text-xs sm:text-base">
                        © 2022 BEAR CAMP NFT. ALL RIGHTS RESERVED
                        </div>
                    </div>
                </div>
            </footer>  
        </>
     );
}
 
export default Footer;